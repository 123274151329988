<template>
  <div id="content">
        <div class="container">
            <div class="row" v-for="organizer in organizersObject" :key="organizer.key">
                <div class="col-sm-3">
                    <div class="organizer-image">
                        <img :src="organizer.imgUrl" />
                    </div>
                </div>
                <div class="col-sm-9">
                    <p class="organizer-title">{{organizer.name}}<br/><span>{{organizer.position}}</span></p>
                    <div class="organizer-text" v-html="organizer.text">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Organizers',
  props:['globalData','pageId'],
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][this.pageId]
    const headData = contents.head

    this.globalData.init.head.title = headData.title
    this.globalData.init.head.keywords = headData.keywords
    this.globalData.init.head.description = headData.description
    
    return{
        contents
    }
  },
  computed:{
    organizersObject(){
        let organizersObject = []
        let organizerOrder = [1,2,7,8,3,4,5,6]
        for(let i = 0; i < organizerOrder.length; i++){
            let order = organizerOrder[i]
            organizersObject.push(this.getUserInfoByIndex(order))
        }
        return organizersObject
    }
  },
  methods:{
    getUserInfoByIndex(index){
        let name = 'name' + index
        let position = 'position' + index
        let text = 'text' + index
        let imgUrl = '/app/img/organizers/' + index +'.jpg'

        if(isLocalhost===true){
          imgUrl = '../img/organizers/' + index +'.jpg'
        }

        return {
            name: this.contents.organizer[name],
            position: this.contents.organizer[position],
            text: this.contents.organizer[text],
            imgUrl: imgUrl
        }
    }
  }
}
</script>
<style>
/*--------------------
        ORGANIZERS
---------------------*/
.organizer-image{position:relative;margin:0 0 30px 0;}
.organizer-image img{width:200px;height:200px;}
.organizer-title{margin:10px 0 20px 0!important;font-size:2rem;line-height:2rem;font-family:'Proxima Nova Bold';}
.organizer-title span{font-size:1rem;line-height:1rem;font-family:'Proxima Nova Regular Italic';}
@media (max-width: 1030px) {
    .organizer-image img{width:175px;height:175px;}

}
@media screen and (max-width:768px) {
    .organizer-image img{width:130px;height:130px;}
}
@media screen and (max-width: 576px) {
    .organizer-title{ font-size: 1.75rem;}
    .organizer-image img{width:150px;height:150px;}
    .organizer-image{
        margin-bottom: 15px;
    }
}

</style>
